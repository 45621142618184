<template>
  <div class="companies-list-page fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table :columns="columnsLabel"
                             :data="data"
                             :options="options"/>
  </div>
</template>

<script>
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'

export default {
  name: 'companiesList',
  components: {DraggableDynamicTable},
  data () {
    return {
      actions: {
        toolbar: [
          {
            icon: 'PLUS',
            iconPack: 'useral',
            color: 'success',
            id: {name: 'insertCompany'},
            type: 'link'
          }
        ],
        leftToolbar: [
          {
            id: 'printTable',
            // i18n: 'draggableDynamicTable.actions.print',
            icon: 'PRINT',
            iconPack: 'useral'
          },
          {
            id: 'downloadTable',
            // i18n: 'draggableDynamicTable.actions.download',
            icon: 'DOWNLOAD',
            iconPack: 'useral'
          },
          {
            id: 'settingTable',
            icon: 'icon-settings',
            iconPack: 'feather'
          }
        ]
      },
      options: {
        id: 'companiesListTable',
        rowKeyField: 'code'
      },
      columnsLabel: [
        {
          field: 'equity',
          i18n: 'companies.table.header.equity',
          width: '100px',
          minWidth: 120,
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'totalDebt',
          i18n: 'companies.table.header.totalDebt',
          width: '100px',
          minWidth: 120,
          color: 'danger',
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'totalAsset',
          i18n: 'companies.table.header.totalAsset',
          width: '100px',
          minWidth: 200,
          color: 'success',
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'name',
          i18n: 'companies.table.header.name',
          width: '200px',
          minWidth: 200,
          locked: true
        },
        {
          field: 'code',
          i18n: 'companies.table.header.code',
          width: '70px',
          minWidth: 70,
          locked: true
        },
        {
          field: 'row',
          i18n: 'companies.table.header.row',
          width: '60px',
          minWidth: 60,
          locked: true,
          footer: {
            type: 'auto-counter'
          }
        }
      ],
      data: [
        {
          row: '1',
          code: '1',
          name: 'فروشگاه تنیس آریا',
          totalAsset: {
            value: '130000',
            type: 'price'
          },
          totalDebt: {
            value: '50000',
            type: 'price'
          },
          equity: {
            value: '80000',
            type: 'price'
          },
          route: {name: 'company', params: {id: 1}}
        },
        {
          row: '2',
          code: '2',
          name: 'فروشگاه تنیس آریا',
          totalAsset: {
            value: '130000',
            type: 'price'
          },
          totalDebt: {
            value: '50000',
            type: 'price'
          },
          equity: {
            value: '80000',
            type: 'price'
          },
          route: {name: 'company', params: {id: 2}}
        },
        {
          row: '3',
          code: '3',
          name: 'فروشگاه تنیس آریا',
          totalAsset: {
            value: '130000',
            type: 'price'
          },
          totalDebt: {
            value: '50000',
            type: 'price'
          },
          equity: {
            value: '80000',
            type: 'price'
          },
          route: {name: 'company', params: {id: 3}}
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)
  }
}
</script>

<style scoped>

</style>
